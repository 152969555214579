.privacyPolicyBanner {
    --bottomMarginMobile: 65px;
    --bottomMarginTablet: 32px;
    --bottomMarginPC: 32px;
    --horisontal-padding: 32px;

    bottom: calc(var(--bottomMarginMobile) + env(safe-area-inset-bottom));
    position: fixed;
    z-index: 1000;
    width: 100%;
}

.informer {
    padding: 20px 16px 16px;
    border-radius: 18px;
    color: #898993;
    line-height: 12px;
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 4px 30px 0 rgba(0, 0, 0, 0.1);
}

.action {
    border-radius: 8px;
    margin-top: 12px;
    height: 50px;
    width: 100%;
    font-size: 13px;
    line-height: 16px;
    font-weight: 525;
    letter-spacing: 0.13px;
}

.text {
    padding: 0 0 0 4px;
    display: block;
}

@media (--snow-tablet) {
    .privacyPolicyBanner {
        max-width: 380px;
        bottom: var(--bottomMarginTablet);
        left: max(var(--horisontal-padding), calc(100% - var(--header-max-width) + var(--horisontal-padding) * 2) / 2);
    }
}

@media (--snow-desktop) {
    .privacyPolicyBanner {
        bottom: var(--bottomMarginPC);
    }
}
